// ** Initial State
const initialState = {
  leavesDropdown: [],
  leaveSettingsDropdown: [],
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedUserLeaveSetting: null,
  add: null,
  selectedLeaveSettingApplicableConfig: null,
  update: null
}

const userLeaveSettings = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_LEAVES_DROPDOWN':
      return { ...state, leavesDropdown: action.data }
    case 'GET_ALL_LEAVE_SETTINGS_DROPDOWN':
      return { ...state, leaveSettingsDropdown: action.data }
    case 'GET_ALL_USER_LEAVE_SETTINGS':
      return { ...state, allData: action.data }
    case 'FILTER_ALL_USER_LEAVE_SETTINGS':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_USER_LEAVE_SETTING':
      return { ...state, selectedUserLeaveSetting: action.selectedUserLeaveSetting }
      
    default:
      return { ...state }
  }
}
export default userLeaveSettings
