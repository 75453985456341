// ** Initial State
const initialState = {
  dropdown: [],
  save: null,
}

const leave = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_EMPLOYEES_LEAVE_LIST':
      return { ...state, dropdown: action.data }
      case 'SAVE_LEAVE':
        return { ...state, save: action.data }
        
    default:
      return { ...state }
  }
}
export default leave
