// ** Initial State
const initialState = {
  dropdown: [],
  save: null,

  payrollEmployeeDropdown: [],
  payperiodDropdown: [],

  data: [],
  total: 1,
  params: {},

  allowences: null,
  saveFixedAllowances: null,
  savePayrollAllowances: null,
  saveCustomAllowance: null,

  deductions: null,
  saveFixedDeductions: null,
  savePayrollDeductions: null,
  saveCustomDeduction: null,
}

const attendance = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_EMPLOYEES_ATTENDANCE_LIST':
      return { ...state, dropdown: action.data }
      case 'SAVE_ATTENDANCE':
        return { ...state, save: action.data }
        
      case 'GET_EMPLOYEES_PAYROLL_LIST':
        return { ...state, payrollEmployeeDropdown: action.data }
      case 'GET_PAYPERIOD_DROPDOWN_LIST':
        return { ...state, payperiodDropdown: action.data }

      case 'GET_PAYROLL_EMPLOYEE_LIST':
        return {
          ...state,
          data: action.data,
          total: action.totalRecords,
          params: action.params
        }

      case 'GET_ALLOWANCES_LIST':
        return { ...state, allowences: action.data }
      case 'SAVE_FIXED_ALLOWANCES':
        return { ...state, saveFixedAllowances: action.data }
      case 'SAVE_PAYROLL_ALLOWANCES':
        return { ...state, savePayrollAllowances: action.data }
      case 'SAVE_CUSTOM_ALLOWANCE':
        return { ...state, saveCustomAllowance: action.data }

      case 'GET_DEDUCTIONS_LIST':
        return { ...state, deductions: action.data }
      case 'SAVE_FIXED_DEDUCTIONS':
        return { ...state, saveFixedDeductions: action.data }
      case 'SAVE_PAYROLL_DEDUCTIONS':
        return { ...state, savePayrollDeductions: action.data }
      case 'SAVE_CUSTOM_DEDUCTION':
        return { ...state, saveCustomDeduction: action.data }
    default:
      return { ...state }
  }
}
export default attendance
